<div (mouseenter)="onMouseEnter()" (mouseleave)="onMouseLeave()" [class.collapsed]="collapsed"
  [class.open]="collapsed && collapsedOpen$ | async" class="sidenav flex flex-col">
  <div class="sidenav-toolbar flex-none flex items-center justify-between">
    <!-- <img [src]="imageUrl$ | async" class="w-6 select-none flex-none">
    <h2 class="title ltr:pl-4 rtl:pr-4 select-none flex-auto">{{ title$ | async }}</h2> -->
    <!-- <img src="assets/img/logo-icon.png" class="w-10 select-none flex-none">
    <h2 class="title ltr:pl-4 rtl:pr-4 select-none flex-auto text-white font-normal text-2xl">openSIS</h2> -->
    <img *ngIf="collapsed" [src]="'data:image/jpeg;base64,'+tenantLogoIcon" class="select-none logo-small flex-none" [alt]="tenantName+' Logo'">
    <img *ngIf="collapsed" [src]="'data:image/jpeg;base64,'+tenantSidenavLogo" class="select-none logo-full flex-none" style="width: 131.39px !important;" [alt]="tenantName+' Logo'">
    <img *ngIf="!collapsed" [src]="'data:image/jpeg;base64,'+tenantSidenavLogo" class="select-none logo-full flex-none" [alt]="tenantName+' Logo'">
   
   
    <button (click)="toggleCollapse()" *ngIf="showCollapsePin$ | async"
      class="expand-btn w-8 h-8 -mr-2 leading-none flex-none hidden lg:block text-black" mat-icon-button type="button">
      <mat-icon *ngIf="!collapsed" [icIcon]="icCollapseSidebar" size="20px"></mat-icon>
      <mat-icon *ngIf="collapsed" [icIcon]="icExpandSidebar" size="20px"></mat-icon>
    </button>
  </div>

  <hr class="mt-0 mb-4 bg-white opacity-25"/>
  <div class="flex items-center ml-4 mr-4 relative">
    <div class="flex-shrink-0 h-10 w-10">
      <img class="h-10 w-10 rounded-full" [src]="userPhoto" alt="Profile picture">
    </div>
    <div class="ml-4 whitespace-no-wrap">
      <div class="text-sm leading-5 font-medium text-gray-900">{{this.defaultValuesService.getFullUserName()}}</div>
      <div class="text-xs leading-5 text-gray-500 opacity-50">{{defaultValuesService.getuserMembershipName()}}</div>
    </div>
    <div>
      <button 
              [matMenuTriggerFor]="addNewMenu" (click)="expandSidebar()" class="absolute right-0 top-3"
              type="button"><mat-icon [icIcon]="icArrowDropDown" size="20px"></mat-icon>
      </button>

      <mat-menu #addNewMenu="matMenu" [overlapTrigger]="false" xPosition="before" yPosition="below">
        <button mat-menu-item *ngIf="membershipName===profile.SuperAdmin || membershipName === profile.SchoolAdmin " (click)="showPreference()">
          <span>{{'preferences' | translate}}</span>
        </button>

        <button mat-menu-item (click)="showMyAccount()">
          <span>{{'myAccount' | translate}}</span>
        </button>

        <button mat-menu-item (click)="openChangePassword()">
          <span>{{'changePassword' | translate}}</span>
        </button>

        <button mat-menu-item (click)="logOut()">
          <span>{{'logout' | translate}}</span>
        </button>
      </mat-menu>
    </div>
  </div>
  <hr class="mb-0 mt-4 bg-white opacity-25"/>
  <vex-scrollbar class="flex-auto">
    <div class="sidenav-items">
      <vex-sidenav-item *ngFor="let item of items; trackBy: trackByRoute"
                        [item]="item"
                        [level]="0"></vex-sidenav-item>
    </div>
  </vex-scrollbar>
</div>
<button (click)="toggleCollapse()" *ngIf="showCollapsePin$ | async"
  class="collapse-btn w-8 h-8 -mr-2 leading-none flex-none hidden lg:block text-black" mat-icon-button type="button">
  <mat-icon *ngIf="!collapsed" [icIcon]="icCollapseSidebar" size="20px"></mat-icon>
  <mat-icon *ngIf="collapsed" [icIcon]="icExpandSidebar" size="20px"></mat-icon>
</button>