<div class="toolbar w-full px-gutter" fxLayout="row" fxLayoutAlign="start center" vexContainer>
  <button (click)="openSidenav()" [fxHide]="!mobileQuery" mat-icon-button type="button">
    <mat-icon [icIcon]="icMenu"></mat-icon>
  </button>

  <a [routerLink]="['/school/dashboards']"
     class="ltr:ml-2 rtl:ml-2 small-mobile-logo">
    <!-- <img class="w-8 select-none" src="assets/img/demo/logo.svg">
    <h1 [fxHide]="mobileQuery" class="title ltr:pl-4 rtl:pr-4 m-0 select-none">VEX</h1> -->
    <img [src]="'data:image/jpeg;base64,'+tenantLogoIcon" class="w-8 select-none" [alt]="tenantName+' Logo'">
    <!-- <h1 [fxHide]="mobileQuery" class="title ltr:pl-4 rtl:pr-4 m-0 select-none"><img src="assets/img/opensistext.png"></h1> -->
  </a>

  <div #megaMenuOriginRef class="rtl:mr-2">
    <div class="-mx-1 ">
    <button *ngIf="impersonateButton"
    (click)="backToSuperAdmin() " matTooltip="{{'backtoSuperAdmin'|translate}} "
    class=" impersonate-back-button"
    type="button">
      <mat-icon class="ic-inline text-white" inline="true">undo</mat-icon>
      <span class="hidden lg:inline ml-2 mb-2">{{'back'|translate}}</span>
    </button>

     <div *ngIf="profileType!=='Homeroom Teacher' && profileType!=='Teacher'" class="inline-block align-middle">
      <button *ngIf="isHorizontalLayout$ | async"
              (click)="openMegaMenu(megaMenuOriginRef)"
              class="global-add-button"
              type="button"><ic-icon [icon]="icAdd" class="ic-inline" inline="true"></ic-icon><span class="hidden lg:inline-block ml-2">{{'addNew'|translate}}</span>
      </button>
    </div>

      <div class="px-1 ml-3">
        <vex-toolbar-notifications></vex-toolbar-notifications>
      </div>
    </div>
  </div>

  <!-- <div *ngIf="(isVerticalLayout$ | async) && isNavbarInToolbar$ | async"
       [fxHide]="mobileQuery"
       class="px-gutter"
       fxFlex="none"
       fxLayout="row"
       fxLayoutAlign="start center">
    <vex-navigation-item *ngFor="let item of navigationItems" [item]="item"></vex-navigation-item>
  </div> -->

  <span fxFlex></span>

  <div class="items-right">
    <div class="px-1">
      <!-- <vex-select-bar></vex-select-bar> -->
      <!-- <vex-select-search-bar></vex-select-search-bar> -->
      <vex-select-bar @stagger></vex-select-bar>
    </div>
  </div>
</div>

<vex-navigation *ngIf="(isVerticalLayout$ | async) && isNavbarBelowToolbar$ | async"
                [fxHide]="mobileQuery"></vex-navigation>
