<div fxLayout="row" fxLayoutAlign="start center" mat-dialog-title>
  <h2 class="headline m-0" fxFlex="auto">
    {{ "changePassword" | translate }}
  </h2>
  <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
    <mat-icon [icIcon]="icClose"></mat-icon>
  </button>
</div>
<mat-divider class="-mx-6 text-border"></mat-divider>
<form [formGroup]="form" (ngSubmit)="submit()">
<mat-dialog-content class="mt-3"  >
  <mat-form-field appearance="outline" color="primary" class="w-full mt-4">
    <mat-label>{{ "currentPassword" | translate }}</mat-label>
      <input formControlName="currentPasswordHash" name="currentPasswordHash" type="password" matInput required />
      <mat-error *ngIf="form.get('currentPasswordHash').hasError('required')">{{'currentPasswordIsRequired'|translate}}
      </mat-error>
  </mat-form-field>
<div class="relative">
  <mat-form-field class="w-full mb-0" appearance="outline" color="primary">
    <mat-label>{{ "newPassword" | translate }}</mat-label>
    <input formControlName="newPasswordHash" name="newPasswordHash" matInput [type]="inputType" #password required/>
    <mat-error *ngIf="form.get('newPasswordHash').hasError('required')">{{'newPasswordIsRequired'|translate}}</mat-error>
    
    <!-- For password visibility on/off -->
    <button (click)="toggleVisibility('inputType')" mat-icon-button matSuffix type="button">
      <mat-icon *ngIf="inputType === 'password'" [icIcon]="icVisibilityOff"></mat-icon>
      <mat-icon *ngIf="inputType === 'text'" [icIcon]="icVisibility"></mat-icon>
    </button>

  </mat-form-field>
  <mat-password-strength #passwordComponent [password]="password.value" class="absolute bottom-1 left-0 w-full"></mat-password-strength>
</div>

  <p class="mb-6 text-primary underline">
    <span class="cursor-pointer" (click)="generate()">{{ "autoGeneratePassword" | translate }}</span>
  </p>

  <mat-form-field class="w-full" appearance="outline" color="primary">
    <mat-label>{{ "confirmNewPassword" | translate }}</mat-label>
      <input formControlName="confirmPasswordHash" name="confirmPasswordHash" type="password" matInput required />
      <mat-error *ngIf="form.get('confirmPasswordHash').hasError('required')">
        {{'confirmNewPasswordIsRequired'|translate}}</mat-error>
  </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions fxLayout="row" fxLayoutAlign="end center" class="pt-0 pb-2">
  <button mat-dialog-close mat-flat-button>{{ "cancel" | translate }}</button>
  <button color="primary" mat-flat-button type="submit" class="text-white ml-2">
    {{ "update" | translate }}
  </button>
</mat-dialog-actions>
</form>